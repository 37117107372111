<template>
    <footer class="footer custom-footer">
        <div class="container">
            <div class="row align-items-center justify-content-lg-between">
                <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <div class="footer-address">
                            <div class="icon-footer">
                            <i class="fa fa-map"></i>
                            </div>
                            <div class="addres-text">
                                <p>420 S. State Street, Dover, DE 19901</p>
                            </div>
                        </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                    <div class="Social-media-icons">
                        <a href="https://twitter.com/derestaurant"><i aria-hidden="true" class="fa fa-twitter"></i></a>
                        <a href="https://www.facebook.com/delawarerestaurantassociation/"><i class="fa fa-facebook"></i> </a>
                        <a href="https://www.instagram.com/delawarerestaurants/"><i aria-hidden="true" class="fa fa-instagram"></i></a>
                        <a href="#"><i class="fa fa-youtube-play"></i> </a>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                    <div class="footer-address justify-end">
                        <div class="icon-footer">
                            <i class="fas fa-phone-volume"></i>
                        </div>
                        <div class="addres-text">
                            <a href="tel:866 DRA-2545">866 DRA-2545</a><a href="tel:(302) 738-2545">(302) 738-2545</a>
                        </div>
                    </div>
                </div>
                <!-- </div> -->
                <div class="row">
                    <div class="col-12">
                        <div class="Social-media-icons">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import {Dynamic} from "../../wl";

export default {
    data() {
        return {
            site_name: "",
            year: new Date().getFullYear()
        };
    },
    created: function () {
        this.site_name = Dynamic.FOOTER_NOTICE;
    }
};
</script>
<style scoped>
footer.footer.custom-footer {
    background: #1a1a1a;
    position: fixed;
    padding: 10px !important;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 1;
}
.footer-address {
    display: flex;
    gap: 10px;
    align-items: center;
}
.footer-address.justify-end {
    justify-content: end;
}
.footer-address.justify-end .addres-text a {
    color: #fff !important;
}
.addres-text {
    display: flex;
    gap: 15px;
}
.footer-address .icon-footer i {
    font-size: 22px;
    color: #a8312d;
}

.addres-text h5 {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
}

.addres-text p {
    color: #fff;
    margin: 0;
}
.Social-media-icons a {
    align-items: center;
    justify-content: center;
    background-color: rgb(255 250 250 / 14%);
    width: 30px;
    display: inline-block;
    text-align: center;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 15px;
    border-radius: 17px;
    box-shadow: 0px 0px 4px #0000002b;
}

.Social-media-icons {
    display: flex;
    gap: 5px;
    justify-content: center;
}
@media only screen and (max-width:992px){
    footer.footer.custom-footer {
        position: static !important;
    }
    .footer-address {
        justify-content: center !important;
        margin: 15px 0px;
        text-align: center;
        flex-direction: column;
    }
}

</style>
