<template>
  <div v-loading.fullscreen.lock="loading">
   <div class="header-section">
    <div v-if="$currentPlatform === 'dra'" class="row">
      <div class="col login-logo">
              <img class="img-fluid logo_img" src="/assets/images/logo-img.png"/>
      </div>
      <div class="col ">
        <div class="toplogin-btn">
          <router-link class="base-button btn btn-default login-text px-2" to="/login">Login</router-link>
        </div>
      <div class="toplogin-btn">
        <router-link class="base-button btn btn-default mr-2 px-2 signup-text" to="/signup">Sign up</router-link>
      </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="row login">
        <div class="col-md-12">
         <div class="row justify-content-center">
           <div class="col-md-8 login-inputs py-3">
           <login-input></login-input>
          </div>
         </div>
        </div>
        
      </div>
     </div>
    </div>
     
     
    </div>
        <!--Banner Section--->
    <section class="banner-section">
      <div class="container">
        <div class="banner-caption">
          <h2>{{ tour.title }}</h2>
        </div>
      </div>
    </section>
    <!--Banner Section--->
    <div class="balloonimgnew">
      <!-- <div class="balloonimg-two">
        <img src="marketing/images/p-slidebg.png" class="img-fluid" alt="" />
      </div> -->
      <div class="container">
       <div class="row">
          <div class="col-md-10 offset-md-1 col-12">
            <!-- <h3 class="text-center mb-5 mt-4">{{ tour.title }}</h3> -->
            <div class="TourVideo_section">
                <iframe  v-if="tour.videoUrl" :src="'https://player.vimeo.com/video/' + tour.videoUrl" style="border: 0;" width="100%" height="520" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen="allowfullscreen" class="iframe-broder text-center">
              </iframe>
            </div>
             
          </div>
        </div>
      </div>
    </div>
    <the-footer v-if="$currentPlatform === 'dra'"/>

  </div>
</template>
<script>
import Vue from "vue";
import LoginInput from "@/views/Pages/LoginInput.vue";
import TheFooter from '@/views/Layout/dra_AppFooter.vue';
import vueVimeoPlayer from "vue-vimeo-player";
Vue.use(vueVimeoPlayer);

export default {
   components: { 
    LoginInput,
    TheFooter 
  },
  data() {
    return {
      loading: false,
      tourId: "",
      tour: {
        title: "Tour",
        videoUrl: ""
      }
    };
  },
  created() {
    if (this.$route.query.id) {
      this.tourId = this.$route.query.id;
      this.getTourVimeoId();
    }
  },
  methods: {
    getTourVimeoId() {
      this.$http.get("get_tour_data/" + this.tourId).then(resp => {
        this.tour.title = resp.data.name;
        this.tour.videoUrl = resp.data.vimeo_video_id;
      });
    }
  }
};
</script>
<style scoped>
body,
html {
  height: 100%;
}
.login-inputs {
  padding-top: 20px;
}
.login{
  margin-right: 0px !important;
}
.iframe-broder{
  /* width:150px;
  height:300px; */
  border-radius: 5px;
 }
.balloonimgnew{
    background-color: white;
    padding-top: 40px;
    padding-bottom: 15px;
    background-size: cover;
    background-position: right;
    position: relative;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
    min-height: 87vh;
}
.TourVideo_section {
   background-color: #ffffff;
   padding: 10px;
   box-shadow: 0px 0px 11px 3px #0000001a;
}


/* dra tour page css */
.logo_img {
    width: 100px;
    padding: 10px;
}

.btn-default {
    background-color: #a8312d;
    border-color: #a8312d;
    /* width: 100px; */
    padding: 8px 15px !important;
    font-size: 14px !important;
}

.login-text {
    position: absolute !important;
}

.signup-text {
    position: absolute;
    right: 130px;
    top: 20px;
    z-index: 9;
    font-weight: 700;
}
/* End dra tour page css */

</style>
